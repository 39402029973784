<template>
  <div class="product">
    <van-nav-bar
      title="检测台账"
      left-text="返回"
      right-text="筛选"
      left-arrow   
      @click-left="toHome" 
      @click-right="chooseshow = true" 
    />
    <van-row class="mt10">
      <van-col span='1'></van-col>
      <van-col span="">{{params.start_time+'~'+params.end_time}}</van-col>     
    </van-row>
    <van-cell-group class="mt10">
      <div id="">
        <ve-table id="" :columns="columns" :table-data="tableData" :border-x="true" scrollWidth="3000px" :border-y="true" /> 
      </div>      
    </van-cell-group>
    <!-- <ve-pagination class="mt10" v-if="page.show"
      :layout = "page.layout"
      :total = "page.total"
      :page-size = "page.offset"
      :paging-count = "page.paging"
      
      @on-page-number-change = "pageChange"/> -->
    <van-pagination class="mt10" v-model="page.currentPage" :total-items="page.total" :show-page-size="page.offset" v-if="page.show" @change='pageChange' force-ellipses>
      <template #prev-text>
        <van-icon name="arrow-left" />
      </template>
      <template #next-text>
        <van-icon name="arrow" />
      </template>
      <template #page="{ text }">{{ text }}</template>
    </van-pagination>

    <van-popup round v-model="chooseshow" position="top" >
      <van-field
        readonly
        clickable
        name="start_time"
        :value="params.start_time"
        label="开始时间"
        placeholder="点击选择时间"
        @click-input="stimeShow = true"
      />
      <van-field
        v-model="params.end_time"
        name="end_time"
        label="结束时间"
        @click-input="etimeShow = true"
        readonly />
        <van-field
          readonly
          v-model="params.sample_name"
          placeholder="输入样品关键词"
          name="sample_name"
          label="样品名称"  @click-input="showSample"/>        
        <van-field
          readonly
          clickable
          name="inspect_type"
          :value="params.type_name"
          label="报检类型"
          placeholder="点击选择报检类型"
          @click-input="showType"
        />
        <van-field
          readonly
          clickable
          name="inspect_dep_id"
          :value="params.inspect_dep_name"
          label="报检部门"
          placeholder="点击选择报检部门"
          @click-input="showDep"
        />
        <!-- <van-field
          readonly
          clickable
          name="equipment_id"
          :value="params.equipment_name"
          label="检测设备"
          placeholder="点击选择检测设备"
          @click-input="showEqu"
        /> -->
        <van-field
          readonly
          clickable
          name="conclusion"
          :value="params.conclusion_val"
          label="检测结果"
          placeholder="点击选择检测结果"
          @click-input="showHg"
        />
        <van-field
          readonly
          clickable
          name="check_status"
          :value="params.check_status_val"
          label="审核状态"
          placeholder="点击选择审核状态"
          @click-input="showCheck"      
        />
        <van-field
          readonly
          clickable
          name="check_emp_id"
          :value="params.check_emp_name"
          label="初检人"
          placeholder="点击选择初检人"
          @click-input="showCj"      
        /> 
        <van-field
          readonly
          clickable
          name="recheck_emp_id"
          :value="params.recheck_emp_name"
          label="复检人"
          placeholder="点击选择复检人"
          @click-input="showFj"      
        />         
      <van-row style="margin: 16px;" gutter="20">
        <van-col span="8"><van-button round block plain size="small" type="default" native-type="button" @click="clickRest()">重置</van-button></van-col>
        <van-col span="16"><van-button title="筛选" round block size="small" type="primary" @click="clickBtn()">确定</van-button></van-col>
        <!-- <van-col span='10'>
          <van-button round block type="info" @click="clickBtn()">确定</van-button>
        </van-col>
        <van-col span='4'></van-col>
        <van-col span='10'>
          <van-button round block type="info" @click="clickRest()">重置</van-button>
        </van-col> -->
      </van-row>  
    </van-popup>    
    <!--开始年月日  --> 
    <van-popup v-model="stimeShow" position="bottom">
      <van-datetime-picker
        type="date"
        :min-date="minDate"
        :max-date="maxDate"
        @confirm="stimeConfirm"
        @cancel="stimeShow = false"
      />
  </van-popup>
  <!-- 结束时间 -->
  <van-popup v-model="etimeShow" position="bottom">
      <van-datetime-picker
        type="date"
        :min-date="minDate"
        :max-date="maxDate"
        @confirm="etimeConfirm"
        @cancel="etimeShow = false"
      />
  </van-popup>
  <!-- 产品搜索 -->
  <van-popup v-model="sampleShow" position="bottom" round :style="{ height: '90%' }">
    <van-search v-model="params.sample_name" placeholder="请输入报检产品关键词" @input="searchSample" :autofocus="autofocus" />
    <van-cell v-for="(item, idx) in productList" :key="idx" :title="item.sample_name + ' ' + item.sample_specs" @click="toProduct(item.sample_name)"/>
  </van-popup>
  <!-- 报检类型 -->
  <van-popup v-model="typeShow" position="bottom">
    <van-picker
      show-toolbar
      value-key="type_name"
      :columns="types"
      @confirm="typeConfirm"
      @cancel="typeShow = false"
    />
  </van-popup>
  <!-- 报检部门 -->
  <van-popup v-model="depShow" position="bottom">
    <van-picker
      show-toolbar
      value-key="dd_dep_name"
      :columns="depTypes"
      @confirm="depConfirm"
      @cancel="typeShow = false"
    />
  </van-popup>  
  <!-- 报检设备 -->
  <!-- <van-popup v-model="equShow" position="bottom">
    <van-picker
      show-toolbar
      value-key="equipment_name"
      :columns="equTypes"
      @confirm="equConfirm"
      @cancel="equShow = false"
    />
  </van-popup> -->
  <!-- 检测结果 -->
  <van-popup v-model="hgShow" position="bottom">
    <van-picker
      show-toolbar
      value-key="val"
      :columns="hgList"
      @confirm="hgConfirm"
      @cancel="hgShow = false"
    />
  </van-popup>
  <!-- 审核状态 -->
  <van-popup v-model="checkShow" position="bottom">
    <van-picker
      show-toolbar
      value-key="cheName"
      :columns="checkList"
      @confirm="checkConfirm"
      @cancel="checkShow = false"
    />
  </van-popup>
  <!-- 初检人 -->
  <van-popup v-model="cjShow" position="bottom">
    <van-picker
      show-toolbar
      value-key="username"
      :columns="cjList"
      @confirm="cjConfirm"
      @cancel="cjShow = false"
    />
  </van-popup>
  <!-- 复检人 -->
  <van-popup v-model="fjShow" position="bottom">
    <van-picker
      show-toolbar
      value-key="username"
      :columns="cjList"
      @confirm="fjConfirm"
      @cancel="fjShow = false"
    />
  </van-popup>  
  <div class="import-excel"  v-if="isPhone == false"  title="导出excel" @click="ToExcel" v-track="{triggerType:'click', title:'导出检测台账',currentUrl: $route.path,behavior: '导出检测台账', duration: new Date().getTime()}">            
    <van-icon  name="down" color="#fff" size="26" style="line-height: 50px;" />
  </div>
  <van-cell-group style="display: none;">
      <div id="exportData">
        <ve-table style="" id="table" ref="filterTable" :columns="columnsAll" :table-data="tableAll" :border-x="true" scrollWidth="2500px" :border-y="true" /> 
      </div>      
    </van-cell-group>                                      
</div>
</template>
<script>
import Vue from 'vue';
import { List, Cell, CellGroup, Tag, Col, Row, Button, Popup, Field, Toast, Dialog,NavBar,DatetimePicker,Picker,Image as VanImage, Search,Uploader,ImagePreview,Icon,Pagination   } from 'vant';
import { mapActions } from 'vuex'
import exportTableUtil from '@/utils/exportTableUtil.js'
import XLSX from 'xlsx'
Vue.use(List);
Vue.use(Cell);
Vue.use(CellGroup);
Vue.use(Tag);
Vue.use(Col);
Vue.use(Row);
Vue.use(Button);
Vue.use(Popup);
Vue.use(Field);
Vue.use(Toast);
Vue.use(Dialog);
Vue.use(NavBar)
Vue.use(DatetimePicker)
Vue.use(Picker)
Vue.use(Search)
Vue.use(VanImage)
Vue.use(Uploader)
Vue.use(ImagePreview)
Vue.use(Icon)
Vue.use(Pagination)
export default {  
name: 'DataDepartments',
components: {},
data() {    
  return {      
    isPhone: this.$common.isMobilePhone(),    
    chooseshow: false, 
    stimeShow: false,
    etimeShow: false,
    searchValue: '',
    typeList: [],      
    loading: false,
    finished: false, 
    sampleShow: false,
    typeShow: false,
    depShow: false,
    equShow: false,
    hgShow: false, 
    checkShow: false,
    cjShow: false,
    fjShow: false,
    imgShow: false,
    productList: [],
    sampleList: [], //样品列表
    types: [], //报检类型
     depTypes: [], //报检部门
    equTypes: [], //设备列表
    cjList: [], //用户列表
    attachList: [], //附件列表
    hgList: [{val:'合格',val_id: '1'},{val:'不合格',val_id:'0'}],
    checkList: [{cheName: '待检测',cheId:'0'},{cheName: '检测中',cheId:'1'},{cheName: '初检完成',cheId:'2'},{cheName: '检测完成',cheId:'3'},{cheName: '让步审批中',cheId:'4'},{cheName: '审批完成',cheId:'5'}],
    page: {
      show: false,
      currentPage: 1,
      total: 0,
      page: 1,
      offset: 5,
    },
    allOffset: 5,
    autofocus: false,
    tableData: [], 
    tableAll: [],
    columns: [   
      { field: "show_id", key: "0", title: "序号", align: "center",width: "100px"},        
      { field: "inspect_code", key: "1", title: "检测编号", align: "center",width: "200px"},
      { field: "sample_name", key: "2", title: "产品名称", align: "center",width: "200px" },
      { field: "sample_specs", key: "3", title: "产品规格型号", align: "center",width: "150px" },
      { field: "jx_name", key: "20", title: "分类", align: "center",width: "100px" },
      { field: "supplier_name", key: "4", title: "来源单位", align: "center",width: "200px" },
      { field: "type_name", key: "5", title: "检测类型", align: "center",width: "140px" },
      { field: "inspect_emp_name", key: "6", title: "送检人", align: "center",width: "100px" },
      { field: "inspect_dep_name", key: "7", title: "报检部门", align: "center",width: "100px" },      
      { field: "check_status", key: "8", title: "检测状态", align: "center",width: "100px" },
      { field: "equipment", key: "9", title: "检测设备", align: "center", width: "100px" },
      { field: "remark", key: "10", title: "备注", align: "center" },
      { field: "check_emp_name", key: "11", title: "检测人", align: "center",width: "100px" },
      { field: "recheck_emp_name", key: "12", title: "复检人", align: "center",width: "100px" },
      { field: "inspect_time", key: "13", title: "报检时间", align: "center" },
      { field: "check_time", key: "14", title: "化验时间", align: "center" },      
      { field: "contract_no", key: "15", title: "合同号", align: "center",width: "150px" },           
      { field: "inspect_batch", key: "16", title: "批号", align: "center",width: "150px" },
      { field: "conclusion", key: "17", title: "整体结论", align: "center",width: "100px" },
      { field: "checkItems", key: "18", title: "检测项目", align: "center",width: "300px"},
      { field: "fujian", key: "19", title: "查看附件", align: "center",width: "80px",
        renderBodyCell: ({ row, column, rowIndex }, h) => {          
          if(row.is_attach) {
            return ( <span>
                <button class="button-demo" on-click={() => this.editRow(row)} >附件</button>                  
              </span>
            );
          }  
        }
      }],
      columnsAll: [     
      { field: "type_name", key: "0", title: "检测类型", align: "center",width: "100px" },
      { field: "inspect_code", key: "1", title: "检测编号", align: "center",width: "200px"},
      { field: "sample_name", key: "2", title: "产品名称", align: "center",width: "100px" },
      { field: "sample_specs", key: "3", title: "产品规格型号", align: "center",width: "150px" },
      { field: "jx_name", key: "20", title: "剂型", align: "center",width: "100px" },
      { field: "supplier_name", key: "4", title: "来源单位", align: "center",width: "200px" },
      { field: "inspect_emp_name", key: "5", title: "送检人", align: "center" },
      { field: "inspect_dep_name", key: "6", title: "报检部门", align: "center",width: "100px" },      
      { field: "check_status", key: "7", title: "检测状态", align: "center",width: "100px" },
      { field: "equipment", key: "8", title: "检测设备", align: "center", width: "100px" },
      { field: "remark", key: "9", title: "备注", align: "center" },
      { field: "check_emp_name", key: "10", title: "检测人", align: "center" },
      { field: "recheck_emp_name", key: "11", title: "复检人", align: "center" },
      { field: "inspect_time", key: "12", title: "报检时间", align: "center",width: "100px" },
      { field: "check_time", key: "13", title: "化验时间", align: "center",width: "100px" },      
      { field: "contract_no", key: "14", title: "合同号", align: "center",width: "150px" },      
      { field: "inspect_batch", key: "15", title: "批号", align: "center",width: "150px" },
      { field: "conclusion", key: "16", title: "整体结论", align: "center",width: "100px" },
      { field: "checkItems", key: "17", title: "检测项目", align: "center",width: "300px"},
      { field: "", key: "19", title: "", align: "",width: ""},
    ],
    params:{      
      check_id: '',
      start_time: this.$common.formatDate(new Date(2022, 0, 1)),
      end_time: this.$common.formatDate(new Date()),
      sample_name: '',
      inspect_type: '',
      type_name: '',
      inspect_dep_id: '',
      inspect_dep_name: '',
      inspect_emp_id: '',
      inspect_emp_name: '',
      conclusion: '',
      check_emp_id: '',
      check_emp_name: '',
      recheck_emp_id: '',
      supplier_code: '',
      check_status: ''                                    
    },
      minDate: new Date(2022, 0, 1),
      maxDate: new Date(),
    };    
  },
  created: function () {  
    this.onLoad()
    this.getTypes() 
    this.getAll() 
  },   
  mounted() {   
  },
  methods: {   
    ...mapActions('listData', ['asyncsetSampleType']),       
    onLoad () {
      var that = this        
      this.$get('statistics/get-check-record-list', 'api', {
        ...this.params,
        ...this.page
      }).then(res => {
        // return
        const data = res.data.list
        data.forEach(ele => {  
          ele.equipment = []
          ele.checkItems = []        
          ele.inspect_time = this.$common.initDate(ele.inspect_time)
          ele.check_time = this.$common.initDate(ele.check_time)
          ele.recheck_time = this.$common.initDate(ele.recheck_time)          
          ele.equipment_items.forEach(equip => {            
            ele.equipment.push(equip.equipment_name)            
          })
          ele.equipment = ele.equipment.join(';')
          if(ele.conclusion == '0') {
            ele.conclusion = '不合格'
          }else {
            ele.conclusion = '合格'
          }
          if(ele.check_status == '0') {
            ele.check_status = '待检测'
          }else if(ele.check_status == '1') {
            ele.check_status = '检测中'
          }else if(ele.check_status == '2') {
            ele.check_status = '初检完成'
          }else if(ele.check_status == '3') {
            ele.check_status = '检测完成'
          }else if(ele.check_status == '4') {
            ele.check_status = '让步审批中'
          }else if(ele.check_status == '5') {
            ele.check_status = '审批完成'
          }
          ele.check_items.forEach(cheItem => {
            ele.checkItems.push('【'+cheItem.check_name+'：'+cheItem.check_value+'】')
          })
          ele.checkItems = ele.checkItems.join(',')
        })
        this.tableData = data
        this.page.total = Number(res.data.count)
        if (this.page.total > this.page.offset) {
          this.page.show = true
        }
      }).catch(err => {
        this.tableData = []
        this.page.total = 0
        this.$notify({ type: 'warning', message: err.message })
      })      
    },
    getAll () {      
      var params = {
        page: 1,
        offset: this.allOffset,
        check_id: '',
        start_time: this.params.start_time,
        end_time: this.params.end_time,
        sample_name: this.params.sample_name,
        inspect_type: this.params.inspect_type,
        // type_name: this.params.type_name,
        inspect_dep_id: this.params.inspect_dep_id,
        // inspect_dep_name: this.params.inspect_dep_name,
        inspect_emp_id: this.params.inspect_dep_id,
        // inspect_emp_name: this.params.inspect_dep_name,
        conclusion: this.params.conclusion,
        check_emp_id: this.params.check_emp_id,
        // check_emp_name: this.params.check_emp_name,
        recheck_emp_id: this.params.recheck_emp_id,
        supplier_code: this.params.supplier_code,
        check_status: this.params.check_status                                        
      }      
      this.$get('statistics/get-check-record-list', 'api', params).then(res => {
        // return
        var data = res.data.list     
        // console.log(data)              
        data.forEach(ele => {  
          ele.equipment = []
          ele.checkItems = []        
          ele.inspect_time = this.$common.initDate(ele.inspect_time)
          ele.check_time = this.$common.initDate(ele.check_time)
          ele.recheck_time = this.$common.initDate(ele.recheck_time)         
          ele.equipment_items.forEach(equip => {            
            ele.equipment.push(equip.equipment_name)            
          })
          ele.equipment = ele.equipment.join(';')
          if(ele.conclusion == '0') {
            ele.conclusion = '不合格'
          }else {
            ele.conclusion = '合格'
          }
          if(ele.check_status == '0') {
            ele.check_status = '待检测'
          }else if(ele.check_status == '1') {
            ele.check_status = '检测中'
          }else if(ele.check_status == '2') {
            ele.check_status = '初检完成'
          }else if(ele.check_status == '3') {
            ele.check_status = '检测完成'
          }else if(ele.check_status == '4') {
            ele.check_status = '让步审批中'
          }else if(ele.check_status == '5') {
            ele.check_status = '审批完成'
          }
          ele.check_items.forEach(cheItem => {
            ele.checkItems.push('【'+cheItem.check_name+'：'+cheItem.check_value+'】')
          })
          ele.checkItems = ele.checkItems.join(',')
        }) 
        this.tableAll = data       
      }).catch(err => {
        this.tableData = []
        this.total = 0
      })      
    },   
    pageChange(val) {         
      this.page.page = val
      this.onLoad()
    },    
     // 获取报检类型
    getTypes() {
      const that = this
      let SampleType = this.$store.state.listData.SampleType
      if (SampleType.length === 0) {
        that.$get('sample-type/get-sample-type-list', 'api', {
        page: 1,
        offset: 100,
        }).then(res => {
          const datas = res.data.list
          that.types = datas
          that.asyncsetSampleType(datas)
        }, err => {
          console.log(err)
          this.$notify({ type: 'warning', message: err.message })
        })
      } else {
        that.types = SampleType
      }
      this.typeStatus = true
    },
     showSample() { 
      this.autofocus = true        
      this.sampleShow = true
      this.searchSample(this.params.sample_name)
    },
    // 报检类型
    showType() {
      this.typeShow = true
      this.getTypes()
    },
    // 报检部门
    showDep() {
      this.depShow = true
      this.getDep()
    },  
    // 检测设备
    showEqu() {
      this.equShow = true
      this.getEqu()
    },
    // 检测结果
    showHg() {
      this.hgShow = true      
    },
    // 审核状态
    showCheck() {
      this.checkShow = true
    },
    // 初检人
    showCj() {
      this.cjShow = true
      this.getUser('', 1)
    },
    // 复检人
    showFj() {
      this.fjShow = true
      this.getUser(1, '')
    },
    // 获取产品名
    searchSample(val) {
      this.$get('inspect/get-sample-list','api', {page: 1,offset: 10,sample_name: val}).then(res => {   
        var data = res.data.list 
        this.productList = data       
      },err => {
        console.log(err)
        this.$notify({ type: 'warning', message: err.message })
      })
    },
    
    // 获取报检部门
    getDep() {
      this.$get('inspect/get-inspect-dep-list','api', {}).then(res => {   
        var data = res.data.list 
        this.depTypes = data       
      },err => {
        console.log(err)
        this.$notify({ type: 'warning', message: err.message })
      })
    },
    // 获取检测设备
    getEqu () {
      this.$get('equipment/get-equipment-list','api', {page:1,offset: 100, equipment_name: ''}).then(res => {   
        var data = res.data.list 
        // console.log(data)
        data.forEach(ele => {
          ele.equipment_name = ele.equipment_name+'-'+ele.qc_code
        })
        this.equTypes = data       
      },err => {
        console.log(err)
        this.$notify({ type: 'warning', message: err.message })
      })
    },
    // 获取用户信息
    getUser(isRecheck,isCheck) {
      var params = {
        page: 1,
        offset: 100,
        username: '',
        is_accept_auth: '',
        is_recheck_auth: isRecheck,
        is_check_auth: isCheck
      }      
      this.$get('user/get-user-list','api', params).then(res => {   
        var data = res.data.data 
        // console.log(data)      
        this.cjList = data       
      },err => {
        console.log(err)
        this.$notify({ type: 'warning', message: err.message })
      })
    },
    // 开始日期确认
    stimeConfirm(time) {    
      this.params.start_time = this.$common.formatDate(time)
      this.stimeShow = false;
    },
    // 结束日期确认
    etimeConfirm(time) {    
      this.params.end_time = this.$common.formatDate(time)
      this.etimeShow = false;      
    },
    // 样品名称确认
    toProduct(val) {
      this.params.sample_name = val
      this.sampleShow = false      
    },
    // 报检类型确认
    typeConfirm(value) {
      // console.log(value)
      this.params.type_name = value.type_name
      this.params.inspect_type = value.id
      this.typeShow = false
    },
    // 报检部门确认
    depConfirm(depVal) {      
      this.params.inspect_dep_name = depVal.dd_dep_name
      this.params.inspect_dep_id = depVal.dd_dep_id
      this.depShow = false
    },
    // 报检设备确认
    equConfirm(epuVal) {
      this.params.equipment_name = epuVal.equipment_name
      this.params.equipment_id = epuVal.id
      this.equShow = false
    },
    // 报检结果确认
    hgConfirm(hgVal) {
      this.params.conclusion_val = hgVal.val
      this.params.conclusion = hgVal.val_id
      this.hgShow = false
    },
    // 审核状态确认
    checkConfirm (checkVal) {
      this.params.check_status = checkVal.cheId
      this.params.check_status_val = checkVal.cheName
      this.checkShow = false
    },
    // 初检人确定 
    cjConfirm(cjVal) {      
      this.params.check_emp_id = cjVal.uid
      this.params.check_emp_name = cjVal.username
      this.cjShow = false
    },
    // 复检人确定 
    fjConfirm(fjVal) {
      // console.log(fjVal)
      this.params.recheck_emp_id = fjVal.uid
      this.params.recheck_emp_name = fjVal.username
      this.fjShow = false
    },
    // 点击确定
    clickBtn() {      
      this.sampleList = []
      this.page.page = 1  
      this.page.currentPage = 1        
      this.pageChange(1)
      this.chooseshow = false
      this.getAll()
    },   
    // 点击重置
    clickRest () {
      this.params = {
        start_time: this.$common.formatDate(new Date(2022, 0, 1)),
        end_time: this.$common.formatDate(new Date()),
        sample_name: '',
        inspect_type: '',
        inspect_dep_id: '',
        inspect_emp_id: '',
        conclusion: '',
        is_inspect_recheck: '',
        equipment_id: '',
        check_status: '',
        is_concession_acceptance: '',
        check_emp_id: '',
        recheck_emp_id: '',
        page: 1,
        offset: 10,         
      }
      this.onLoad()
    },   
    // 获取附件信息
    getFjList(id) {        
      this.$post('statistics/get-sample-attach', 'api', { id: id }).then(res => {
          const datas = res.data.attach                    
          this.attachList = datas
          this.imgShow = true
          ImagePreview({
            images: this.attachList,
            showIndex: true,
            loop: true,
            closeable: true       
          })
      }, err => {
          console.log(err)
          this.imgShow = false
          this.attachList = []            
      })
    },
    // 查看附件
    editRow(val) {           
      this.getFjList(val.id)
    },
    toHome() {
      this.$router.push({
        path: '/statistics'     
      })
    },     
    // 导出excel
    ToExcel () {    
      this.allOffset = 1000
      let tbody = document.querySelector('#table tbody')
      var tr = tbody.getElementsByTagName('tr')
      tr.forEach(ele => {
        if(ele.className.indexOf('ve-table-body-tr') == -1){  
           tbody.removeChild(ele)         
        }
      })    
      let wb = XLSX.utils.table_to_book(document.querySelector('#table'))
      let elt = document.getElementById('exportData')     
      exportTableUtil.exportTable(elt, '检测台账', '检测台账', 1)
    }
  }          
};
</script>
<style scoped>
/* .product .van-list {
  margin-top: 66px;
} */
 .status{
  position: absolute;
  top: 30px;
  right: 0px;
  width: 80px;
  height: 80px;
  /* opacity: 40%; */
  /* transform:rotate(-45deg);
  -webkit-transform:rotate(-45deg);
  -moz-transform:rotate(-45deg); */
 }
 .border-tb{
  border-top: dashed 1px #f5f5f5;
  border-bottom: dashed 1px #f5f5f5;
  padding: 8px 0;
  margin: 8px 0;
 }
 .elasticlayer {
   height: 60%;
 }
 .tac {
   text-align: center;
 }
 .add-one {
   width: 50px;
   height: 50px;
   background: wheat;
   position: fixed;
   bottom: 100px;
   z-index: 99999;
   border-radius: 50%;
   text-align: center; 
   opacity: 0.8;
   right: 0; 
   cursor: pointer;
 }
 .red {
   color: red;
 }
 .van-cell-group--inset {
    height: 300px;
    overflow: auto;
}
.icon-del {
  position: absolute;
  right: 20px;
  z-index: 99;
  top: 10px;
}
.icon-add {
  margin-left: 20px;
}
.searchInp {
  position: fixed;
  top: 0;
  width: -webkit-fill-available;
  z-index: 9999;
}
.van-tag {
  border-radius: 16px;
}
.import-excel {
   width: 50px;
   height: 50px;
   background: #3d7cf3;
   position: fixed;
   bottom: 150px;
   z-index: 999;
   border-radius: 50%;
   text-align: center; 
   opacity: 0.8;
   right: 0; 
   cursor: pointer;
}
#exportData .ve-table-body{
  display: none;
}
</style>